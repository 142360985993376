<template>
  <div>
    <load-profile></load-profile>
    <section class="invoice-preview-wrapper">
      <b-row v-if="data.id" class="invoice-preview">
        <b-col cols="12" md="8" xl="9" class="pr-md-0">
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ $t("field.deliveryNote") }}
                    </h3>
                  </div>
                  <p class="card-text mb-25">
                    {{ data.billToCustomerName }}
                  </p>
                  <p class="card-text mb-0">
                    {{ data.billToAddress }}
                  </p>
                </div>

                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    <span class="invoice-number">#{{ data.no }}</span>
                  </h4>
                </div>
              </div>
            </b-card-body>

            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="p-0">
                  <h6>{{ $t("field.invoiceTo") }}:</h6>
                  <h6 class="mb-25">
                    {{ data.customerName }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.customerCode }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.customerAddress }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.customerContactNo }}
                  </p>

                  <h6 class="mt-2">{{ $t("field.shipment") }}:</h6>
                  <h6 class="mb-25">
                    {{ data.shipToContact }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.shipToAddress }}
                  </p>
                </b-col>

                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6>{{ $t("field.information") }}:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">{{ $t("field.orderNo") }} :</td>
                          <td>
                            {{ data.orderNo }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.shipmentDate") }} :</td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.postingDate | formatDate("LL") }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.paymentMethod") }} :
                          </td>
                          <td>
                            <span class="font-weight-bold">
                              {{
                                data.paymentMethodCode
                                  ? data.paymentMethodCode
                                  : "-"
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.locationCode") }} :</td>
                          <td>
                            {{ data.locationCode }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.orderReference") }} :
                          </td>
                          <td>
                            {{ data.externalDocumentNo }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <div class="order-summary">
              <n-table
                class="px-2 order-summary-table"
                ref="table"
                :fields="fields"
                :items="displayItems"
              >
                <template #cell(quantity)="data">
                  {{ data.item.quantity }} {{ data.item.uomCode }}
                </template>
              </n-table>
            </div>
          </b-card>
        </b-col>

        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <download-pdf-button
              :loading="loading"
              :repository="SalesShipmentRepository"
              :id="data.id"
              :file-name="$t('field.deliveryNote') + ' - ' + data.orderNo"
              :disabled="!data.id"
              :variant="!data.id ? 'secondary' : 'success'"
              block
            >
              <i class="las la-truck"></i>
              {{ $t("button.downloadDeliveryNote") }}
            </download-pdf-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BBadge, BTr, BTh } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import NTable from "@/components/NTable";
import tableFields from "./viewTableFields";
import DownloadPdfButton from "@/components/DownloadPdfButton";

const SalesShipmentRepository = Repository.get("salesShipment");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BBadge,
    BTr,
    BTh,

    NTable,
    NButtonLoading,
    DownloadPdfButton,
  },
  data() {
    return {
      loading: false,
      data: {},
      items: [],
      displayItems: [],
      SalesShipmentRepository,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      SalesShipmentRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
            this.items = [...data.lines];
            const displayItems = [];
            this.items.forEach((element) => {
              if (element.attachedToLineNo == 0) {
                displayItems.push(element);
              }
            });
            this.displayItems = [...displayItems];
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...tableFields];

    const resource = "agent-sales-shipment";
    const route = "sales-shipment";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>