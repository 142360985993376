export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'description',
    subKey: 'no',
    label: 'field.description',
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'unitPrice',
    label: 'field.unitPrice',
    type: 'currency',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'reasonCode',
    label: 'field.reasonCode',
  },
];
